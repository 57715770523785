import React, { useState } from "react";
import SignInMicrosoft from "./sign-in-forms/sign-in-microsoft";
import SignInEmail from "./sign-in-forms/sign-in-email";
import("./style.scss");

const OptionMap = (type) => {
  const map = {
    "oauth.microsoft": (loginConfig, tenantConfig) => (
      <SignInMicrosoft loginConfig={loginConfig} tenantConfig={tenantConfig} />
    ),
    "email.": (loginConfig, tenantConfig) => (
      <SignInEmail loginConfig={loginConfig} tenantConfig={tenantConfig} />
    ),
  };

  return map[type] || (() => null);
};

const SignInMultiOptions = (props) => {
  return (
    <div className="login-options-container" style={{ margin: "0 auto" }}>
      {props.tenantConfig.loginOptions
        .filter((option) => option?.type != "email")
        .map((option) => (
          <div
            key={option?.provider || option?.type}
            style={{ margin: "10px 0" }}
          >
            {OptionMap(`${option?.type || ""}.${option?.provider || ""}`)(
              option,
              props.tenantConfig
            ) || <></>}
          </div>
        ))}
      {props.tenantConfig.loginOptions.filter(
        (option) => option?.type != "email"
      ).length > 0 && (
        <div
          className="login-options-separator"
          style={{ margin: "20px 0", position: "relative" }}
        >
          <div className="login-options-separator__line"></div>
          <div className="login-options-separator__or">
            <span>OR</span>
          </div>
        </div>
      )}
      <div style={{ margin: "10px 0" }}>
        <SignInEmail tenantConfig={props.tenantConfig} />
      </div>
    </div>
  );
};

export default SignInMultiOptions;
