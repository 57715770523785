import moment from "moment";
import { isNil, pathOr, propOr } from "ramda";
import { getCustomerFiltersFromStorage } from "src/main_app/services/local_storage/customer-filters-storage";

const convertDate = (date) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD");
};

const getSearchData = (onTabCustomerID) => (filteredData) => {
  const searchData = {};
  searchData[`search[customer_id]`] = onTabCustomerID;
  if (isNil(filteredData)) return searchData;
  searchData[`search[alert__type]`] = propOr(null, "alertType", filteredData);
  searchData[`search[severity]`] = propOr(null, "alertSeverity", filteredData);
  searchData[`from`] = convertDate(
    pathOr(null, ["dateRange", "startDate"], filteredData)
  );
  searchData[`to`] = convertDate(
    pathOr(null, ["dateRange", "endDate"], filteredData)
  );
  searchData[`search[name]`] = propOr(null, "customerName", filteredData);
  searchData[`search[type]`] = propOr(null, "customerType", filteredData);
  searchData[`search[industry]`] = propOr(null, "customerIndustry", filteredData);

  //
  // const filterFromStorage = getCustomerFiltersFromStorage();
  // if (isNil(filterFromStorage)) {
  //   searchData[`search[industry]`] = propOr(
  //     null,
  //     "customerIndustry",
  //     filteredData
  //   );
  // } else {
  //   searchData["search[industry]"] = propOr(
  //     null,
  //     "industry",
  //     filterFromStorage
  //   );
  // }
  return searchData;
};

export default getSearchData;
